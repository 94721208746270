import * as React from 'react';
import { Fragment } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import { useSelector } from 'react-redux';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Rider from './Rider';
import Online from './Online';
import Offline from './Offline';
import Editor from './Editor';
import Admin from './Admin';

const drawerWidth = 220;

export default function SideBarDesktop() {
  const user = useSelector((state) => state.user.user);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);  
  const userRole = useSelector((state) => state.user.currentRole);

  
  const addressText = (address) => {
    let text = String(address) || address;
    let last6 = text.slice(text.length - 4);
    let first3 = text.substring(0,3);
    return first3+'***'+last6;
  }

  return (
    
    <Box sx={{ display: {md: 'flex', sm: 'none', xs: 'none'} }}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
        
          {(user.name && isAuth) && 
          <Link to='/profile' component={RouterLink} sx={{ width: '100%', textDecoration: 'none', color: 'inherit', maxWidth: 360, bgcolor: 'background.paper' }}>
            <ListItem alignItems="flex-start">
            <ListItemAvatar>
              <Avatar alt={user.name} src={user.profile} />
            </ListItemAvatar>
            <ListItemText
              primary={addressText(user.name)}
              secondary={addressText(user.email)}
            />
          </ListItem>
        </Link>
          }
          
        <Divider/>
          
          {isAuth && user.name ?
            userRole === '' ?
            <Online/>
            : 
            <Fragment>
               {userRole === 'admin' && <Admin/>}      
               {userRole === 'editor' && <Editor/>}
               {userRole === 'dispatch rider' && <Rider/>}              
            </Fragment>
                   
          :
          <Offline/>
          }

        </Box>
      </Drawer>
      
    </Box>
  );
}