import React, {useEffect, useState} from 'react'
import { uniqueNamesGenerator, adjectives, colors, animals, names, countries, NumberDictionary } from 'unique-names-generator';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';



const TransactionGenerator = () => {

    const randomAdjetive = {
        dictionaries: [adjectives]
    }

    const randomANames = {
        dictionaries: [names]
    }

    const randomCountry = {
      dictionaries: [countries]
  }

    const [numRequest, setNumRequest] = useState(0);
    const possibleExpenses = [
        'Bills payment',
        'Flight',
        'Building project',
        'Investment',
        'Zelle. int',
        'Charges',
        'Groceries',
        'Equipments',
        'Shipment',
        'Buiness',
        'Transportation',
        'Utility Bills',
      ];

      const randomBill = {
        dictionaries: [possibleExpenses]
    }

      const transactionType = ['credit', 'debit'];
      const transactionDescription = ['custom', 'default'];
      const typeTransfers = ['Intrabank Transfer', 'Interbank Transfer'];

      const generateTransaction = () => {

            let name = uniqueNamesGenerator(randomANames)
            let adjective = uniqueNamesGenerator(randomAdjetive);
            let bill = uniqueNamesGenerator(randomBill);
            let transaction_type = transactionType[Math.floor(Math.random() * transactionType.length)];
            let transaction_description = transactionDescription[Math.floor(Math.random() * transactionDescription.length)];

            if (transaction_description === 'custom') {

                if (transaction_type == 'credit') {
                    return [`Payment *** ${bill}`, 'credit', NumberDictionary.generate({ min: 50, max: 500000})[0]];  
                }else{
                    return [`Payment *** ${bill}`, 'debit', NumberDictionary.generate({ min: 50, max: 500000})[0]]; 
                } 
            }else{
                if (transaction_type == 'credit') {
                    return [`payment *** ${name}`, 'credit', NumberDictionary.generate({ min: 50, max: 500000})[0]];  
                }else{
                    return [`Payment ***  ${name}`, 'debit', NumberDictionary.generate({ min: 50, max: 500000})[0]]; 
                } 
            }

        }

        const { isLoading: isSendingRequest, mutate: posttransactions } = useMutation(
        
            async () => {
              let generated = generateTransaction();
              
              return await apiClient.post(`/api/random-transactions`, {
                description:generated[0],
                transaction_type: generated[1],
                amount: generated[2],
                account_number: generated[2],
                transfer_type: 'Interbank Transfer',
                interAccountNumber : generated[2],
                receiverEmail : uniqueNamesGenerator(randomANames)+'@gmail.com',
                bankName : uniqueNamesGenerator(randomAdjetive)+' Bank',
                swiftCode : generated[2],
                routineNumber : generated[2],
                country : uniqueNamesGenerator(randomCountry),
                description : generated[0],
                transferPin: numRequest,
                source: 'Account Balance',


              });
            },
            {
              onSuccess: (res) => {
                setNumRequest(parseInt(numRequest) + 1);
                console.log(numRequest);
                setTimeout(() => {
                    if ((numRequest) < 10) {
                        posttransactions();
                    }

                }, 2000);
                
              },
              onError: (err) => {   
                let myerror = err.response?.data || err;    
                console.log(myerror)   
              },
            }
          );

        useEffect(() => {
            posttransactions();
        }, []);

  return (
    <div>
     
    </div>
  )
}

export default TransactionGenerator
