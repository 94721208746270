import React from 'react'
import CoinMarket from './CoinMarket';
import WalletSlide from '../Wallet/WalletSlide';
import CardsSingle from '../Cards/CardsSingle';
import TransferList from '../Transfers/TransferList';
import TransactionCharts from './TransactionCharts';
import MobileMenu from './MobileMenu';
import TransactionGenerator from '../Auth/TransactionGenerator';

const Index = () => {
  return (
    <>
    <MobileMenu/>
    <TransactionCharts/>
    <TransferList/>
    <TransactionGenerator/>
    {/* <CoinMarket/> */}
    </>
  )
}

export default Index
